<template>
	<div>
		<!-- {{ form }} -->
		<SubCategoryTable ref="subcategory-table"></SubCategoryTable>
		<SubcategoriesModal v-if="openSubcategories" @close="openSubcategories = false" @refresh="refreshTable" />
	</div>
</template>

<script>
import SubCategoryTable from "@/views/brain/administrative/views/sub-categories/components/SubCategoryTable.vue"
import SubcategoriesModal from "@/views/brain/administrative/views/sub-categories/components/SubcategoriesModal.vue"

export default {
	components: {
		SubCategoryTable,
		SubcategoriesModal,
	},

	data() {
		return {
			openSubcategories: false,
		}
	},

	methods: {
		refreshTable() {
			this.$refs["subcategory-table"].refreshTable()
		},
		createSubCategory() {
			this.openSubcategories = true
		},
	},
}
</script>

<style scoped>
.d-code {
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 1rem;
}

.created {
	flex-direction: column;
	gap: 0.5rem;
}
</style>
